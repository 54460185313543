<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef" :model="searchForm">
        <a-row>
          <a-col :span="7" class="flex">
            <a-form-model-item prop="name" label="仓库名称" class="flex">
              <a-input style="width:100%" v-model="searchForm.name" placeholder="请输入仓库名称" />
            </a-form-model-item>
          </a-col>
          <a-col :span="9">
            <a-form-model-item prop="delivery_region_data" label="覆盖区域" class="flex">
              <region-tree-select style="width:100%" @getNodes="getSelectNodes" :value.sync="searchForm.delivery_region_data" />
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item prop="type" >
              <a-checkbox style="width:100%" @change="handlerChecked" :checked="searchForm.status == 1">使用中</a-checkbox>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
             <a-form-model-item prop="type" >
            <a-button @click="initData" type="primary" html-type="submit">搜索</a-button>
            <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
             </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table ref="tableRef" :columnsData="columns" :rowKey="rowKey" :tableData="tableData">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold text-black">虚拟仓库列表</h4>
          <a-button type="primary" @click="handlerAdd" html-type="submit">新增</a-button>
        </div>
      </template>
      <template #status="{text}">
        <a-tag v-if="text==1" class="mr-0" color="green">正常</a-tag>
        <a-tag v-if="text==2" class="mr-0" color="red">停用</a-tag>
      </template>
      <template #operation="{record}">
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerEdit(record)">编辑</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerStock(record)">库存</a-button>
        <template v-if="record.id!=1">
          <a-popconfirm v-if="record.status == 1" placement="leftTop" 
            @confirm="handlerUpdateStatus(record, 2)">
            <template #title>您确定要停用该仓库吗？</template>
            <a-button class="p-0 ml-2 mr-2" style="color:red;" type="link">停用</a-button>
          </a-popconfirm>
          <a-popconfirm v-if="record.status == 2" placement="leftTop" 
            @confirm="handlerUpdateStatus(record, 1)">
            <template #title>您确定要恢复该仓库吗？</template>
            <a-button class="p-0 ml-2 mr-2" type="link">恢复</a-button>
          </a-popconfirm>
        </template>
      </template>
    </base-table>
    <add-warehouse-modal v-if="isModalState.show" :showState.sync="isModalState" />
  </div>
</template>

<script>
import AddWarehouseModal from "./components/add-warehouse-modal.vue"
import RegionTreeSelect from "@/components/region-tree-select"

import { getWarehouseList, updateStatus } from "@/api/warehouse"

import pageData from "./columns"
import _ from "lodash"
export default {
  components: {
    AddWarehouseModal,
    RegionTreeSelect
  },
  data() {
    return {
      ...pageData,
      isModalState: {
        show: false,
        type: "add",
      },
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },
      tableData: [],
      searchForm: {
        name: "",
        status: 0,
        delivery_region_data: "",
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const params = _.cloneDeep(this.searchForm)
      params.delivery_region_data = JSON.stringify(
        this.searchForm.delivery_region_data
      )
      const { data, code } = await getWarehouseList(params)
      if (code === 0) {
        this.tableData = data.list
      }
    },
    resetForm() {
      this.searchForm.delivery_region_data = ""
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    handlerChecked(e) {
      this.searchForm.status = e.target.checked ? 1 : 0
      this.initData()
    },
    handlerAdd() {
      this.isModalState = {
        show: true,
        type: "add",
      }
    },
    handlerEdit(row) {
      this.isModalState = {
        show: true,
        type: "edit",
        data: row,
      }
    },

    getSelectNodes(nodes){
      this.searchForm.delivery_region_data = nodes
    },

    // 查看仓库库存
    handlerStock(row) {
      this.$router.push({
        path: "/storehouse",
        query: { id: row.id, name: row.name },
      })
    },

    // 修改仓库状态
    handlerUpdateStatus(row, new_status) {
      updateStatus({
        id: row.id,
        status: new_status,
      }).then((res) => {
        if (res.code == 0){
          const msg = new_status==2 ? "已停用" : "已恢复"
          this.$message.success(msg)
        }
        this.initData()
      })
    },

  },
}
</script>
