
export const rowKey = 'id'
export const columns = [ 
  {
    title: '仓库名称',
    dataIndex: 'name',
    align: 'center',
    width: 150
  },
  {
    title: '覆盖区域',
    dataIndex: 'delivery_region',
  },
  {
    title: '仓库状态',
    dataIndex: 'status',
    width: 80,
    align: 'center',
    slots: {
      customRender: 'status'
    }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
    width: 180,
    slots: {
      customRender: 'operation'
    }
  },
]


export default {
  rowKey,
  columns,
}
