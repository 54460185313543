<template>
  <a-modal
    :visible.sync="showState.show"
    :title="showState.type === 'edit'?'编辑仓库':'添加仓库'"
    destroyOnClose
    width="40%"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <div class="modal-search-bar" style="margin-bottom:-30px;">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="addForm"
      >
        <a-form-model-item prop="name" label="仓库名称">
          <a-input allowClear v-model="addForm.name" placeholder="仓库名称"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="delivery_region_data" label="覆盖区域">
          <region-tree-select @getNodes="getSelectNodes" :value.sync="region_tree_data" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import RegionTreeSelect from "@/components/region-tree-select/index"
import { addWarehouse, editWarehouse } from "@/api/warehouse"

export default {
  components: { RegionTreeSelect },
  props: {
    showState: {
      type: Object,
    },
  },
  data() {
    return {
      wrapperCol: { span: 20 },
      labelCol: { span: 4},
      addForm: {
        name: "",
        delivery_region_data: "",
      },
      region_tree_data: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      if (this.showState.type === "edit") {
        this.addForm.id = this.showState.data.id
        this.addForm.name = this.showState.data.name
        const treeData = []
        this.showState.data.delivery_region_adcode.map((province) => {
          const provinceData = {
            type: 1,
            adcode: province.province_adcode,
            selectChildren: [],
          }
          treeData.push(provinceData)

          if (province.city_list.length > 0) {
            province.city_list.map((city) => {
              const cityData = {
                type: 2,
                adcode: city.city_adcode,
                selectChildren: []
              }
              provinceData.selectChildren.push(cityData)
              if (city.area_list.length > 0) {
                city.area_list.map((area) => {
                  const areaData = {
                    type: 3,
                    adcode: area.area_adcode,
                    selectChildren: []
                  }
                  cityData.selectChildren.push(areaData)
                })
              }
            })
          }
        })
        this.region_tree_data = treeData
      }
    },
    // 保存
    handleOk() {
      const params = this.addForm
      // if(typeof this.addForm.delivery_region_data === 'string'){
      //   this.addForm.delivery_region_data = JSON.parse(this.addForm.delivery_region_data)
      // }
      // params.delivery_region_data = JSON.stringify(
      //   this.addForm.delivery_region_data
      // )
      params.delivery_region_data = JSON.stringify(this.region_tree_data)

      if (this.showState.type === "edit") {
        editWarehouse(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("编辑仓库成功！")
            this.showState.show = false
            this.$parent.initData()
          }
        })
      } else {
        addWarehouse(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增仓库成功！")
            this.showState.show = false
            this.$parent.initData()
          }
        })
      }
    },

    getSelectNodes(nodes){
      this.region_tree_data = nodes
    },
    resetForm() {
      this.$refs.searchRef.resetFields()
    },
    handlerCancel() {
      this.showState.show = false
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>